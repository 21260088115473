<template>
  <router-view />
</template>

<script>
import { provideI18n } from "./i18nPlugin";
export default {
  setup() {
    provideI18n({
      locale: "en",
      messages: {
        en: {
          editor: "Editor",
          logout: "Logout",
          reference: "Reference",
          events: "Events",
          settings: "Settings",
          profile: "Profile",
          director: "Director",
          inventories: "Inventories",
          plants: "Plants",
          materials: "Materials",
          projects: "Projects",
          begin_date: "Begin Date",
          partner: "Partner",
          status: "Status",
          style: "Style",
          select_a: "Select a ",
          moods_en: [
            "*",
            "Contemporary",
            "Rural",
            "Zen",
            "Exotic",
            "Mediterranean",
            "Relax",
            "Extra",
            "Color",
          ],
          search: "Keyword Search",
          name: "Name",
          latin_name: "Latin name",
          blossom_period: "Blossom period",
          planting_period: "Planting period",
          code: "Code",
          client_name: "Client name",
          client_email: "Client email",
          created_date: "Created date",
          filename: "File name",
          filesize: "File size",
          uploaded_date: "Uploaded date",
          uploaded_by: "Uploaded by",
          update_date: "Updated date",
          d_padination: "Showing {first} to {last} of {totalRecords} entries",
          january: "January",
          fevrier: "Febuary",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          septembre: "September",
          october: "October",
          november: "November",
          december: "December",
          type: "Type",
          soil_type: "Soil type",
          soil_ph: "Soil ph",
          soil_moisture: "Soil moisture",
          sun_exposure: "Sun exposure",
          flower_form: "Flower form",

          "cover-top": "Cover top",
          perennial: "Perennial",
          "tree-and-shrub": "Tree and shrub",
          climbing: "Climbing",

          "clay-silty": "Clay silty",
          rocky: "Rocky",
          limestone: "Limestone",
          "clay-limestone": "Clay limestone",

          neutral: "Neutral",
          alkaline: "Alkaline",
          acid: "Acid",

          tolerant: "Tolerant",
          wet: "Wet",
          dry: "Dry",

          "full-sun": "Full sun",
          "partial-shade": "Partial shade",
          "full-shade": "Full shade",

          panicule: "Panicule",
          capitule: "Capitule",
          corymbe: "Corymbe",
          epis: "Epis",
          ombelle: "Ombelle",
          grappe: "Grappe",
          solitaire: "Solitaire",
          spadice: "Spadice",
          cyme: "Cyme",
          home: "HOME",
          username: "Username",
          password: "Password",
          signin: "Sign in",
          year: "year",
          days: "days",
          yesterday: "Yesterday",
          less_than_a_day: "Less than a day",
          project_details: "Project",
          summary: "Summary",
          media: "Media",
          shopping_list: "Shopping list",
          documents: "Documents",
          renders: "Renders",
          beforeAfter: "Before After",
          plans: "Plans",
          comments: "Messaging",
          new: "New",
          "client:wip": "Client - In Progress",
          "client:done": "Client - Done",
          "landscaper:wip": "Landscaper - In Progress",
          "landscaper:done": "Landscaper - Done",
          review: "Review",
          landscaper: "Landscaper",
          management: "Management",
          late: "Late",
          deadline_date: "DeadLine date",
        },
        fr: {
          editor: "Editeur",
          logout: "Déconnexion",
          reference: "Réference",
          events: "Evènements",
          settings: "Paramètre",
          profile: "Profile",
          director: "Directeur",
          inventories: "Inventaires",
          plants: "Plantes",
          materials: "Materiels",
          projects: "Projets",
          begin_date: "Debut",
          partner: "Partenaire",
          style: "Style",
          select_a: "Selectionner un ",
          moods_en: [
            "*",
            "Contemporain",
            "Champêtre",
            "Zen",
            "Exotique",
            "Méditerranéen",
            "Relaxante",
            "Extra",
            "Colorée",
          ],
          search: "Recherche mot-clé",
          name: "Nom",
          latin_name: "Nom latin",
          blossom_period: "Période de fleuraison ",
          planting_period: "Période de plantation",
          code: "Code",
          client_name: "Client",
          client_email: "Email",
          created_date: "Date de création",
          status: "Status",
          update_date: "MAJ",
          filename: "Titre",
          filesize: "Poids",
          uploaded_date: "Date",
          uploaded_by: "Par",
          d_padination: "De {first} à {last} des {totalRecords} resultats",
          january: "Janvier",
          fevrier: "Février",
          march: "Mars",
          april: "Avril",
          may: "Mai",
          june: "Juin",
          july: "Juillet",
          august: "Août",
          septembre: "Septembre",
          october: "Octobre",
          november: "Novembre",
          december: "Decembre",
          type: "Type",
          soil_type: "Type de sol",
          soil_ph: "Ph du sol",
          soil_moisture: "Humidité du sol",
          sun_exposure: "Exposition au soleil",
          flower_form: "Forme de la fleur",

          "cover-top": "Couvre-sol",
          perennial: "Vivace",
          "tree-and-shrub": "Arbre / Arbuste",
          climbing: "Grimpante",

          "clay-silty": "Argilo-limoneux",
          rocky: "Caillouteux",
          limestone: "Calcaire",
          "clay-limestone": "Argilo-calcaire",

          neutral: "Neutre",
          alkaline: "Calcaire",
          acid: "Acide",

          tolerant: "Tolerant",
          wet: "Humide",
          dry: "Sec",

          "full-sun": "Soleil",
          "partial-shade": "Mi-Ombre",
          "full-shade": "Ombre",

          panicule: "Panicule",
          capitule: "Capitule",
          corymbe: "Corymbe",
          epis: "Epis",
          ombelle: "Ombelle",
          grappe: "Grappe",
          solitaire: "Solitaire",
          spadice: "Spadice",
          cyme: "Cyme",

          home: "ACCEUIL",
          username: "Utilisateur",
          password: "Mot de pase",
          signin: "Se connecter",

          year: "an",
          days: "jours",
          yesterday: "Hier",
          less_than_a_day: "Moins d'un jour",
          project_details: "Projet",
          summary: "Résumé",
          media: "Média",
          shopping_list: "Liste de courses",
          documents: "Documents",
          renders: "Rendus",
          beforeAfter: "Avant Après",
          plans: "Plans",
          comments: "Discussion",
          new: "Nouveau",
          "client:wip": "Client - En cours",
          "client:done": "Client - Terminé",
          "landscaper:wip": "Paysagiste - En cours",
          "landscaper:done": "Paysagiste - Terminé",
          review: "Révision",
          landscaper: "Paysagiste",
          management: "Management",
          late: "Retard",
          deadline_date: "Echéance",
        },
      },
    });
  },
};
</script>
