import { computed, toRef, reactive } from 'vue'
// import { defineAbility } from '@casl/ability'
import http from '../services/http'

const store = reactive({
  user: null,
  ability: null
})

async function  login ({username, password}) {
  const {data} = await http.core.post('/session/login', {username, password})
  localStorage.setItem('pxtoken', data.token)
  return getMe()
}

async function  logout () {
  await http.core.post('/session/logout')
  localStorage.setItem('pxtoken', '')
  store.user = null
  store.ability = null
}

async function getMe () {
  console.log('[auth]getMe')
  return new Promise((resolve, reject) => {
    http.core.get('/session/me')
      .then( ({data}) => {
        store.user = data
        update()
        resolve(data)
      })
      .catch( err => {
        reject(err)
      })
  })
}
  
async function isLogged () {
  console.log('[auth]isLogged')
  if (store.user) {
    return new Promise(resolve => resolve(true) )
  } else {
    await getMe()
    return !!store.user
  }
}

function isLoggedSync () {
  return !!store.user
}


function  can (...args) {
  if (!store.ability) {
    return false
  }
  return store.ability.can(...args)
}

const isLandscaper = computed(() => {
  if (store.user && store.user.user && store.user.user.roles && store.user.user.roles.indexOf('landscaper') !== -1) {
    return true
  }

  return false
})


const isAdmin = computed(() => {
  if (store.user && store.user.user && store.user.user.roles && store.user.user.isAdmin) {
    return true
  }

  return false
})

function update () {
  if (!store.user) {
    store.ability = null
    return
  }
  // store.ability = defineAbility((can) => {
  //   console.log(store.user.roles)
  //   store.user.roles.forEach(role => {
  //     switch (role) {
  //       case 'admin':
  //         can('read', 'landscaper')
  //         break
  //       case 'landscaper':
  //         can('close', 'landc')
  //         break
  //     }
  //   })
  // })
}

export function useAuth () {
  return {
    user: toRef(store, 'user'),
    isLandscaper,
    isAdmin,

    login,
    logout,
    isLogged,
    isLoggedSync,
    getMe,
    can
  }
}