import axios from 'axios'
// import K from '../constants'

function buildCfg(cfg) {
  const out = {
    headers: {
       Authorization: `Bearer ${localStorage.getItem('pxtoken')}`,
       responseType: 'arraybuffer'
    }
  }

  if (cfg) {
    for (const key in cfg) {
      if (key === 'headers') {
        Object.assign(out[key], cfg[key])
      } else {
        out[key] = cfg[key]
      }
    }
  }

  return out
}

function getApiUrl (url) {
  return '/core' + url
}

const plugin = {
  axios: axios,
  getApiUrl: getApiUrl,
  core: {
    get (url, cfg) {
      const u = getApiUrl(url)
      return axios.get(u, buildCfg(cfg))
    },
    delete (url, cfg) {
      const u = getApiUrl(url)
      return axios.delete(u, buildCfg(cfg))
    },
    put (url, data, cfg) {
      const u = getApiUrl(url)
      return axios.put(u, data, buildCfg(cfg))
    },
    post (url, data, cfg) {
      console.log(url, data, cfg)
      const u = getApiUrl(url)
      return axios.post(u, data, buildCfg(cfg))
    }
  }
}

export default plugin
