import {
  createRouter,
  createWebHistory
} from "vue-router";

const routes = [{
    path: "/session",
    name: "Session",
    component: () =>
      import( /* webpackChunkName: "session" */ "./layouts/Empty.vue"),
    children: [{
      path: "login",
      name: "SessionLogin",
      component: () =>
        import( /* webpackChunkName: "session" */ "./pages/session/Login.vue"),
      meta: {
        guest: true,
      },
    }, {
      path: "logout",
      name: "SessionLogout",
      component: () =>
        import( /* webpackChunkName: "session" */ "./pages/session/Logout.vue"),
      meta: {
        guest: true,
      },
    }],
  }, {
    path: "/hortus-book",
    name: "HortusBook",
    component: () => import( /* webpackChunkName: "hortusBook" */ "./layouts/HortusBook.vue"),
    children: [{
      path: "simple-page/:mediaId",
      name: "SimplePage",
      component: () => import( /* webpackChunkName: "hortusBook" */ "./pages/hortus-book/SimplePage.vue"),
      meta: {
        guest: true,
      },
    }, {
      path: "before-after-page/:mediaId",
      name: "BeforeAfterPage",
      component: () => import( /* webpackChunkName: "hortusBook" */ "./pages/hortus-book/BeforeAfter.vue"),
      meta: {
        guest: true,
      },
    }, {
      path: "plan-page/:mediaId",
      name: "PlanPage",
      component: () => import( /* webpackChunkName: "hortusBook" */ "./pages/hortus-book/PlanPage.vue"),
      meta: {
        guest: true,
      }
    }, {
      path: "plants-list-page",
      name: "PlantsListPage",
      component: () => import( /* webpackChunkName: "hortusBook" */ "./pages/hortus-book/PlantsListPage.vue"),
      meta: {
        guest: true,
      },
    }, {
      path: "materials-list-page",
      name: "MaterialsListPage",
      component: () => import( /* webpackChunkName: "hortusBook" */ "./pages/hortus-book/MaterialsListPage.vue"),
      meta: {
        guest: true,
      },
    }],
  },
  {
    path: "/",
    name: "Root",
    component: () =>
      import( /* webpackChunkName: "root" */ "./layouts/Default.vue"),
    children: [{
        path: "",
        name: "Dashboard",
        component: () =>
          import( /* webpackChunkName: "root" */ "./pages/Home.vue"),
      },
      {
        path: "/materials",
        name: "MaterialHome",
        component: () =>
          import( /* webpackChunkName: "process" */ "./pages/materials/Home.vue"),
      },
      {
        path: "/plants",
        name: "PlantHome",
        component: () =>
          import( /* webpackChunkName: "plants" */ "./pages/plants/Home.vue"),
      },
      {
        path: "/projects",
        name: "ProjectHome",
        component: () =>
          import(
            /* webpackChunkName: "projects" */
            "./pages/projects/Home.vue"
          ),
      },
      {
        path: "/editor",
        name: "EditorHome",
        component: () =>
          import(
            /* webpackChunkName: "editor" */
            "./pages/editor/Home.vue"
          ),
      },
      {
        path: "/landscapers",
        name: "LandscaperHome",
        component: () =>
          import(
            /* webpackChunkName: "landscapers" */
            "./pages/landscapers/Home.vue"
          ),
      },
      {
        path: "/projects/:id",
        name: "ProjectDetail",
        component: () =>
          import(
            /* webpackChunkName: "projects" */
            "./pages/projects/Details.vue"
          ),
      },
      {
        path: "/app-config",
        name: "AppConfigHome",
        component: () =>
          import(
            /* webpackChunkName: "appconfig" */
            "./pages/appconfig/Home.vue"
          ),
      },
      {
        path: "/app-config/:appId/:id",
        name: "AppConfigEdit",
        component: () =>
          import(
            /* webpackChunkName: "appconfig" */
            "./pages/appconfig/Edit.vue"
          ),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;